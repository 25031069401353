/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/cardo"
import "@fontsource/cardo/700.css"
import "@fontsource/cardo/400-italic.css"
import "@fontsource/syne"
import "@fontsource/syne/500.css"
import "@fontsource/syne/600.css"
import "@fontsource/syne/700.css"
import "@fontsource/montserrat"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/500-italic.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/700-italic.css"
import "./src/styles/tailwind.less"

const setViewHeight = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
    console.log("IntersectionObserver polyfilled ;)")
  }
}

export const onRouteUpdate = () => {
  setViewHeight()
  window.addEventListener("resize", setViewHeight)
}
